import { Controller } from "@hotwired/stimulus"

import { pushGa4StorageToDataLayer, viewSearchResultsEvent } from "@/helpers/ga4_helper"

export default class FiltersFormController extends Controller<HTMLFormElement> {
  declare readonly filterLinkTarget: HTMLAnchorElement
  declare readonly hiddenFieldsetTargets: HTMLFieldSetElement[]
  static targets = ["filterLink", "hiddenFieldset"]

  connect(): void {
    viewSearchResultsEvent()
    pushGa4StorageToDataLayer()

    // Reset form controls so back button behaves as expected
    this.element.reset()
  }

  applyFilter(e: Event): void {
    const url = (e.target as HTMLElement)?.dataset.url
    if (!url) return

    e.preventDefault()

    this.filterLinkTarget.setAttribute("href", url)
    this.filterLinkTarget.click()
  }

  showAll(e: Event): void {
    const button = e.currentTarget as HTMLButtonElement
    const hiddenFilters = this.hiddenFieldsetTargets.find((fieldset) => {
      return button.parentElement?.contains(fieldset)
    })

    hiddenFilters?.removeAttribute("hidden")
    button?.remove()
  }
}

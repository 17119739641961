import { debounce } from "@/helpers/utils"
import { isDesktop } from "@/helpers/window_size"
import ModalController from "@/controllers/modal_controller"

export default class FiltersModalController extends ModalController {
  declare toggleViewStyle: () => void

  initialize(): void {
    this.toggleViewStyle = debounce(this.toggleAttrs.bind(this), 100)

    // No debounce on initialize
    this.toggleAttrs()
  }

  close(): void {
    super.close()
    window.scrollTo(0, 0)
  }

  private toggleAttrs(): void {
    this.animationOff()
    super.close()

    if (isDesktop()) {
      this.useNoStyleView()
    } else {
      this.useModalView()
    }

    this.animationOn()
  }
}

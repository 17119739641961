import { Controller } from "@hotwired/stimulus"

export default class ModalController extends Controller<HTMLDivElement> {
  static targets = ["container", "loader"]
  declare readonly containerTarget: HTMLDivElement
  declare readonly loaderTarget: HTMLDivElement

  private static readonly FADE_IN_DURATION = 100 // See `--fade-in-duration`
  private static readonly HIDDEN_ATTR = "hidden"
  private static readonly KEYDOWN_EVENT = "keydown"
  private static readonly INITIAL_CLASS = "--initial"
  private static readonly NO_STYLE_CLASS = "no-style"
  private static readonly OPEN_CLASS = "--modal-open"

  private shouldAnimate = true

  animationOff(): void {
    this.shouldAnimate = false
  }

  animationOn(): void {
    this.shouldAnimate = true
  }

  backgroundClose(e: Event): void {
    // Modal spans entire viewport when open, so checking background click means comparing
    // click target to modal itself.
    if (e.target === this.element) {
      this.close()
    }
  }

  close(e?: KeyboardEvent): void {
    if (e && e.type === ModalController.KEYDOWN_EVENT && e.key === undefined) {
      // this ignores artifical keydown events, such as those
      // sent when using browser auto-fill
      return
    }

    this.setAnimationClass()
    document.body.classList.remove(ModalController.OPEN_CLASS)
    document.removeEventListener("focus", this.focusInModal, true)
    this.element.setAttribute(ModalController.HIDDEN_ATTR, "")
  }

  get containerId(): string {
    return this.containerTarget.id
  }

  open(): void {
    this.setAnimationClass()
    document.body.classList.add(ModalController.OPEN_CLASS)
    document.addEventListener("focus", this.focusInModal, true)
    this.element.removeAttribute(ModalController.HIDDEN_ATTR)

    // Focus modal on open (this also ensures `keydown` handler works without user interaction).
    // Elements with CSS `visibility: hidden` aren't focusable, so wait for animation.
    window.setTimeout(() => {
      this.containerTarget.focus()
    }, ModalController.FADE_IN_DURATION)

    document.dispatchEvent(new Event(`${this.identifier}:open`))
  }

  startLoading(): void {
    this.loaderTarget.hidden = false
  }

  stopLoading(): void {
    this.loaderTarget.hidden = true
  }

  useModalView(): void {
    this.element.classList.remove(ModalController.NO_STYLE_CLASS)
  }

  useNoStyleView(): void {
    document.body.classList.remove(ModalController.OPEN_CLASS)
    this.element.classList.add(ModalController.NO_STYLE_CLASS)
    this.element.removeAttribute(ModalController.HIDDEN_ATTR)
  }

  private focusInModal = (): void => {
    // To preserve focus within modal (e.g. from tabbing), check if the active element not inside
    // a modal and if so, and the element isn't covered by a second modal re-focus the container
    const activeElement = document.activeElement as HTMLElement
    const containingModal = activeElement.closest(".ui-component-modal")

    if (!containingModal || !elementVisible(activeElement)) {
      this.containerTarget.focus()
    }
  }

  private setAnimationClass(): void {
    this.element.classList.toggle(ModalController.INITIAL_CLASS, !this.shouldAnimate)
  }
}

const elementVisible = (element: HTMLElement): boolean => {
  // element.checkVisibility only checks viewport bounds,
  // to see if an element is within a lower modal -we need
  // to check it's behind something else
  const rect = element?.getBoundingClientRect() as DOMRect

  const centerX = rect.left + rect.width / 2
  const centerY = rect.top + rect.height / 2
  const topElement = document.elementFromPoint(centerX, centerY)

  return topElement === element || element.contains(topElement)
}

import { Controller } from "@hotwired/stimulus"

export default class ProgressBarController extends Controller {
  declare readonly progressBarTarget: HTMLProgressElement
  declare readonly progressTextTarget: HTMLSpanElement

  declare readonly resultsPerPageValue: number
  declare readonly totalResultsValue: number
  declare cumulativeResultValue: number

  static targets = ["progressBar", "progressText"]

  static values = {
    resultsPerPage: Number,
    totalResults: Number,
    cumulativeResult: Number
  }

  incrementProgress(): void {
    // Increment cumulative results safely, clamping at `totalResultsValue`
    this.cumulativeResultValue = Math.min(
      this.cumulativeResultValue + this.resultsPerPageValue,
      this.totalResultsValue
    )
  }

  // Update the dataset and the visible progress bar
  cumulativeResultValueChanged(newValue: number): void {
    this.progressTextTarget.textContent = newValue.toString()
    this.progressBarTarget.value = newValue
  }
}
